.cell_eye {
  padding-left: 0px !important;
}
.manage_nurse_eye_modal .Dialogmodal .MuiDialogTitle-root {
  display: none !important;
}
.green_upload_container {
  margin-bottom: 15px;
}

.upload_ins {
  font-size: 15px;
  color: #000000;
  font-weight: 400;
  margin-left: 10px;
  position: relative;
  top: 3px;
}
.manage_nurse_table_div table thead tr th:nth-child(7),
.manage_nurse_table_div table tbody tr th:nth-child(7),
.manage_nurse_table_div table thead tr th:nth-child(6),
.manage_nurse_table_div table tbody tr td:nth-child(6){
text-align: center !important;
}




.manage_nurse_table_div table thead tr th:nth-child(4),
.manage_nurse_table_div table tbody tr td:nth-child(4),
.manage_nurse_table_div table thead tr th:nth-child(5),
.manage_nurse_table_div table tbody tr td:nth-child(5),
.manage_nurse_table_div table thead tr th:nth-child(8),
.manage_nurse_table_div table tbody tr td:nth-child(8)
{
  text-align: center;
  justify-content: center !important;
}

.nurse_history_table_div table thead tr th:nth-child(1),
.nurse_history_table_div table tbody tr th:nth-child(1),
.nurse_history_table_div table thead tr th:nth-child(3),
.nurse_history_table_div table tbody tr td:nth-child(3),
.nurse_history_table_div table thead tr th:nth-child(4),
.nurse_history_table_div table tbody tr td:nth-child(4),
.nurse_history_table_div table thead tr th:nth-child(5),
.nurse_history_table_div table tbody tr td:nth-child(5),
.nurse_history_table_div table thead tr th:nth-child(6),
.nurse_history_table_div table tbody tr td:nth-child(6),
.nurse_history_table_div table thead tr th:nth-child(7),
.nurse_history_table_div table tbody tr td:nth-child(7),
.nurse_history_table_div table thead tr th:nth-child(8),
.nurse_history_table_div table tbody tr td:nth-child(8)
{
  text-align: center;
}

.nurse_history_table_div table tbody tr td:nth-child(10)
{
  text-align: center !important;
}
.manage_nurse_table_div table thead tr td :nth-child(4),
.manage_nurse_table_div table thead tr td :nth-child(5){
  text-align: center !important;
}

.upload_ins_popup .MuiDialog-paperFullWidth {
  min-width: 20%;
  max-width: 23% !important;
  width: auto;
}
.upload_ins_popup .Dialogmodal span{
  font-family: titillium web !important;
}

.manage_nurse_delete_popup .MuiDialog-paperFullWidth {
  min-width: 25%!important;
  max-width: 30%!important;
  width: auto!important;
  height: 30% !important;
}

.arabic_cls .manage_nurse_table_div table thead tr th:nth-child(2),
.arabic_cls .manage_nurse_table_div table tbody tr td:nth-child(2),
.arabic_cls .manage_nurse_table_div table thead tr th:nth-child(4),
.arabic_cls .manage_nurse_table_div table tbody tr td:nth-child(4),
.arabic_cls .manage_nurse_table_div table thead tr th:nth-child(3),
.arabic_cls .manage_nurse_table_div table tbody tr td:nth-child(3),
.arabic_cls .manage_nurse_table_div table thead tr th:nth-child(1),
.arabic_cls .manage_nurse_table_div table tbody tr td:nth-child(1)
{
  text-align: center;
}


.arabic_cls .manage_nurse_table_div table thead tr th:nth-child(5),
.arabic_cls .manage_nurse_table_div table tbody tr td:nth-child(5)
{
  text-align: center;
}

.arabic_cls .manage_nurse_table_div table thead tr th:nth-child(6),
.arabic_cls .manage_nurse_table_div table tbody tr td:nth-child(6)
{
  text-align: right;
}

.arabic_cls .manage_nurse_table_div table thead tr th:nth-child(6),
.arabic_cls .manage_nurse_table_div table tbody tr td:nth-child(6)
{
  text-align: right !important;
}

.arabic_cls .manage_nurse_table_div table thead tr th:nth-child(7),
.arabic_cls .manage_nurse_table_div table tbody tr th:nth-child(7),
.arabic_cls .manage_nurse_table_div table thead tr th:nth-child(7),
.arabic_cls .manage_nurse_table_div table tbody tr td:nth-child(7)
{
  text-align: right !important;
}


.arabic_cls .nurse_history_table_div table thead tr th:nth-child(6),
.arabic_cls .nurse_history_table_div table tbody tr td:nth-child(6),
.arabic_cls .nurse_history_table_div table thead tr th:nth-child(5),
.arabic_cls .nurse_history_table_div table tbody tr td:nth-child(5),
.arabic_cls .nurse_history_table_div table thead tr th:nth-child(4),
.arabic_cls .nurse_history_table_div table tbody tr td:nth-child(4),
.arabic_cls .nurse_history_table_div table thead tr th:nth-child(3),
.arabic_cls .nurse_history_table_div table tbody tr td:nth-child(3),
.arabic_cls .nurse_history_table_div table thead tr th:nth-child(2),
.arabic_cls .nurse_history_table_div table tbody tr td:nth-child(2),
.arabic_cls .nurse_history_table_div table thead tr th:nth-child(1)
{
  text-align: center;
}

.arabic_cls .nurse_history_table_div table tbody tr td:nth-child(1){
  justify-content: center !important;
}

.arabic_cls .nurse_history_table_div table thead tr th:nth-child(7),
.arabic_cls .nurse_history_table_div table tbody tr td:nth-child(7)
{
  text-align: right;
}