.remark_txt {
  display: inline-block;
  width: 160px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.ticketSubmit {
  font-size: 16px !important;
  background-color: var(--app-theme) !important;
  color: #fff !important;
  text-transform: none !important;
  width: 20%;
  border-radius: 50px !important;
  font-weight: 500 !important;
}

.lbltxt {
  color: black !important;
  display: block;
  font-size: 16px;
  font-weight: 400 !important;
}

.ar_lbltxt {
  color: black !important;
  display: block;
  font-size: 16px;
  font-weight: 400;
  text-align: start !important;
}

.ticket_table_div table thead tr th span {
  color: black !important;
}

.ticket_table_div table tbody tr td {
  font-size: 14px !important;
  color: #707070 !important;
}

.ticket_table_div table thead tr th,
.ticket_table_div table tbody tr td,
.ticket_table_div table tbody tr th {
  text-align: left;
}

.ticket_table_success .MuiDialog-paperFullWidth {
  width: 20%;
  max-width: 25%;
}

.ticket_table_success .MuiPaper-rounded {
  border-radius: 30px;
}

.ticket_success_head {
  font-family: titillium web !important;
  color: #510f30;
}

/* .ticket_table_div table tbody tr td:nth-child(6) {
  text-transform: lowercase !important;
} */
.arabic_cls .ticket_table_div table thead tr th:nth-child(4),
.arabic_cls .ticket_table_div table tbody tr td:nth-child(4),
.arabic_cls .ticket_table_div table thead tr th:nth-child(5),
.arabic_cls .ticket_table_div table tbody tr td:nth-child(5){
  /* text-transform: lowercase !important; */
  text-align: right;

}


.arabic_cls .ticket_table_div table thead tr th:nth-child(6),
.arabic_cls .ticket_table_div table tbody tr td:nth-child(6) {
  text-align: right;
  text-transform: uppercase !important;
}
.ticket_table_div table tbody tr td:nth-child(6) {
  text-transform: lowercase ;
}
.arabic_cls .ticket_table_div table thead tr th:nth-child(2),
.arabic_cls .ticket_table_div table tbody tr td:nth-child(2){
  text-transform: lowercase !important;

}

.arabic_cls .ticket_table_div table thead tr th:nth-child(3),
.arabic_cls .ticket_table_div table tbody tr td:nth-child(3) {
  text-align: center;
  /* text-transform: uppercase !important; */
}

.arabic_cls .ticket_table_div table thead tr th:nth-child(1),
.arabic_cls .ticket_table_div table tbody tr td:nth-child(1),
.arabic_cls .ticket_table_div table thead tr th:nth-child(7),
.arabic_cls .ticket_table_div table tbody tr th:nth-child(7) {
  text-align: center;
}


.arabic_cls .ticket_table_div table thead tr th:nth-child(1),
.arabic_cls .ticket_table_div table tbody tr td:nth-child(1)
 {
  text-align: center !important;
  justify-content: center !important;
}

.arabic_cls .remark_txt {
  text-align: right;
  float: right;
}

.progress_container_audio {
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 40px;
}

.progress_container {
  display: flex;
  align-items: center;
  margin: 0px 0 0 25px;
  width: 80%;
  height: 15px;
}

.progress_container span,
.progress_container_audio span {
  margin: 0 3px;
}

progress {
  width: 100%;
  height: 4px;
  border: none;
}

progress::-webkit-progress-bar {
  background-color: #ffffff;
  border-radius: 25px;
}

progress::-webkit-progress-value {
  background-color: #510f30;
  border-radius: 25px;
}